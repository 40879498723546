<template>
  <!-- 问答讨论 / 问答讨论详情 -->
  <div class="main-contain1">
    <!-- <div class="head">
      <div class="head-title">问答讨论 / 问答讨论详情</div>
      <div class="returnbt">
        <div class="bt" @click="toback">返回</div>
      </div>
    </div>
    <div class="line"></div> -->
    <div class="item">
      <div class="title">{{ item.title }}
        <div class="replybt" @click="replybt">回复</div>
      </div>

      <div class="item-two">
        <div v-if="item.is_top === 1" class="item-two-bt item-two-bt-active">
          置顶
        </div>
        <div v-if="item.is_teacher_join === 1" class="item-two-bt">
          老师参与
        </div>
      </div>
      <div class="text-contain">
        <div class="text" v-html="item.content"></div>
      </div>
      <div class="bottom-contain">
        <div class="bottom-left">
          <el-image class="elimage" :src="item.avatar == null ? image09 : item.avatar" />
          <div class="teach">{{ item.author_name }}</div>
          <div class="time">{{ (item.created_at) | commFormat }}</div>
          <div class="time">发布</div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right-item">
            <template v-if="item.like_status === 0">
              <el-image class="elimage1" :src="icon2" />
              <div class="num">{{ item.like_count }}</div>
            </template>
            <template v-else-if="item.like_status === 1">
              <el-image class="elimage1" :src="icon5" />
              <div class="num">{{ item.like_count }}</div>
            </template>
            <!-- <el-image class="elimage1" :src="icon2" />
            <div class="num">{{ item.like_count }}</div> -->
          </div>
          <div class="line2"></div>
          <div class="bottom-right-item">
            <el-image class="elimage2" :src="icon1" />
            <div class="num">{{ item.view_count }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="totaltitle">共{{ total }}条回复</div>
    <div v-if="dialogVisible">
      <div slot="dialogbody" class="dialogbody">
        <Tinymce ref="editor" v-model="queryParams.content" style="height: auto; border-radius: 22px"
          @setInput="getValue"></Tinymce>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bt" @click="canclebt">取消</div>
        <div class="bt btactive" @click="submitbt">确定</div>
      </div>
    </div>
    <div class="replycontain">
      <div class="replyitem" v-for="(item, index) in replylist" :key="index">
        <div class="itemup">
          <template v-if="!item.to_user_name">
            <el-image class="elimage" :src="item.avatar == null ? image09 : item.avatar" />
            <div class="nametext">{{ item.user_name }}</div>
          </template>
          <template v-else>
            <div class="nametext">{{ item.user_name }}&nbsp;&nbsp;&nbsp;&nbsp;回复&nbsp;&nbsp;&nbsp;&nbsp;{{
                item.to_user_name
            }}</div>
          </template>
          <div class="timetext">
            {{ item.created_at | commFormat }}
          </div>
        </div>
        <div class="itembottom">
          <div class="left">
            <div v-html="item.content"></div>
          </div>
          <div class="right">
            <div class="bottom-right-item" @click="setlike(item)">
              <template v-if="item.like_status === 0">
                <el-image class="elimage2" :src="icon2" />
                <div class="num">{{ item.like_count }}</div>
              </template>
              <template v-else-if="item.like_status === 1">
                <el-image class="elimage2" :src="icon5" />
                <div class="num">{{ item.like_count }}</div>
              </template>
            </div>
            <div class="line2"></div>
            <div class="bottom-right-item" @click="childreply(item)">
              <el-image class="elimage3" :src="icon3" />
              <div class="num">{{ item.reply_count }}</div>
            </div>
            <div class="line2" v-if="item.is_mine == 1"></div>
            <div class="bottom-right-item" @click="deletreply(item)" v-if="item.is_mine == 1">
              <el-image class="elimage3" :src="icon4" />
            </div>
          </div>
        </div>
        <div v-if="item.replylistflag" style="margin-bottom: 20px;">
          <div slot="dialogbody" class="dialogbody">
            <Tinymce ref="editor" v-model="queryParams.content" style="height: auto; border-radius: 22px"
              @setInput="getValue"></Tinymce>
          </div>
          <div slot="dialogfooter" class="dialogfooter">
            <div class="bt" @click="canclefollowUp(item)">取消</div>
            <div class="bt btactive" @click="followupsubmint(item)">确定</div>
          </div>
        </div>
        <div class="replychild">
          <div class="replyitem" v-for="(item1, index) in item.child" :key="index">
            <div class="itemup">
              <el-image class="elimage" :src="item1.avatar == null ? image09 : item1.avatar" />
              <div class="nametext">
                <span>{{ item1.user_name }}
                  <span v-if="item1.to_user_name">回复 {{ item1.to_user_name }}</span>
                </span>
                <!-- <span v-else>{{ item1.user_name }} 回复 {{ item.user_name }}</span> -->
              </div>
              <div class="timetext">
                {{ item1.created_at * 1000 | commFormat }}
              </div>
            </div>
            <div class="itembottom">
              <div class="left">
                <div v-html="item1.content"></div>
              </div>
              <div class="right">
                <div class="bottom-right-item" @click="childreply1(item1)">
                  <el-image class="elimage3" :src="icon3" />
                </div>
                <div class="line2" v-if="item1.is_mine == 1"></div>
                <div class="bottom-right-item" v-if="item1.is_mine == 1" @click="deletchildreply(item)">
                  <el-image class="elimage3" :src="icon4" />
                </div>
              </div>
            </div>
            <div v-if="item1.replylistflag1" style="margin-bottom: 20px;">
              <div slot="dialogbody" class="dialogbody">
                <Tinymce ref="editor" v-model="queryParams.content" style="height: auto; border-radius: 22px"
                  @setInput="getValue"></Tinymce>
              </div>
              <div slot="dialogfooter" class="dialogfooter">
                <div class="bt" @click="canclefollowUp(item1)">取消</div>
                <div class="bt btactive" @click="followupsubmint1(item1)">确定</div>
              </div>
            </div>
            <div class="line3"></div>
          </div>
        </div>
        <div class="line3"></div>
      </div>
    </div>
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    
    </el-dialog> -->

    <!-- <Customdialog ref="customdialog" title="发表评论">

    </Customdialog> -->
  </div>
</template>
<script>
// import Customdialog from "@/components/customdialog.vue";
import {
  getQaReplyList,
  addQaReplyAdd,
  setQaReplyLike,
  QaReplyDelete,
  qareply_deatil,
} from "@/api/qareply";
import dayjs from "dayjs";
import Tinymce from "@/components/VueTinymce/index.vue";

export default {
  name: "courselearning",
  components: { Tinymce },
  data() {
    return {
      dialogVisible: false,
      icon1: require("@/assets/coursedetails/查看.png"),
      icon2: require("@/assets/coursedetails/点赞.png"),
      icon3: require("@/assets/coursedetails/回复.png"),
      icon4: require("@/assets/shanchu.png"),
      image09: require("@/assets/logo2018.png"),
      icon5: require("@/assets/coursedetails/dzxz.png"),
      item: '',
      replylist: [],
      total: 0,
      queryParams: {
        qa_reply_id: '',
        qa_id: '',
        content: ''
      },
      // flag: false
    };
  },
  filters: {
    dataformat: function (value) {
      if (!value) return "";
      return dayjs.unix(value).format("mm");
    },
    commFormat: function (value) {
      if (!value) return "";
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
  computed: {
    qa_id() {
      return this.$route.query.qa_id;
    },
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    // this.queryParams.qa_id = this.qa_id
    // this.initdata();
    // this.getqareplylist({ qa_id: this.qa_id });
  },
  methods: {
    // 主体详情
    initInfo() {
      qareply_deatil({ id: this.qa_id }).then((res) => {
        if (res.code == 0) {
          this.item = res.data;
        }
      });
    },
    initdata(obj) {
      this.item = obj;
      this.queryParams.qa_id = obj.id
      this.getqareplylist({ qa_id: obj.id });
    },


    getqareplylist(params) {
      getQaReplyList(params)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.total = response.data.data.length;
            this.replylist = response.data.data
            response.data.data.forEach(item => {
              this.$set(item, "replylistflag", false)
              if (item.child.length > 0) {
                item.child.forEach(el => {
                  this.$set(el, "replylistflag1", false)
                })
              }
            })
            console.log(" this.replylist", this.replylist);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addQaReplyAdd() {

    },
    setlike(item) {
      this.setQaReplyLike({
        id: item.id,
      });
    },

    setQaReplyLike(params) {
      setQaReplyLike(params)
        .then((response) => {
          if (response.code == 0) {
            this.getqareplylist({ qa_id: this.queryParams.qa_id })
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletreply(item) {
      this.QaReplyDelete({
        id: item.id,
      });
    },
    deletchildreply(item) {
      this.QaReplyDelete({
        id: item.id,
      });
    },
    QaReplyDelete(params) {
      QaReplyDelete(params)
        .then((response) => {
          if (response.code == 0) {
            this.$message.success("删除成功")
            this.getqareplylist({ qa_id: this.queryParams.qa_id });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toback() {
      this.$router.push({
        path: "/home/virtualsimulationexperiment/experimentdetails",
        query: {
          id: this.id,
          btid: 7,
          returncomid: "Interactiveanswer",
        },
      });
    },
    replybt() {
      this.dialogVisible = !this.dialogVisible
      this.queryParams.qa_reply_id = ''
      this.queryParams.content = ''
    },
    canclebt() {
      this.dialogVisible = false
    },
    //取消追评
    canclefollowUp(row) {
      row.replylistflag = false
    },
    //追评
    followupsubmint(row) {
      // this.queryParams.qa_id = row.id
      this.submitbt()
      this.queryParams.content = ''
    },
    childreply(item) {
      this.queryParams.qa_reply_id = item.id;
      this.queryParams.content = ''
      item.replylistflag = !item.replylistflag
    },
    //追评回复
    childreply1(item) {
      item.replylistflag1 = !item.replylistflag1
      this.queryParams.content = ''
    },
    //追评回复
    followupsubmint1(row) {
      this.queryParams.qa_reply_id = row.id;
      this.submitbt()
      row.replylistflag1 = false
      this.queryParams.content = ''
    },
    submitbt() {
      addQaReplyAdd(this.queryParams)
        .then((response) => {
          if (response.code == 0) {
            this.$message.success("回复成功")
            this.dialogVisible = false
            this.getqareplylist({ qa_id: this.queryParams.qa_id });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取富文本得值
    getValue(data) {
      this.queryParams.content = data;
    },
  },
};
</script>
<style scoped lang="scss">
.main-contain1 {
  border: 1px solid transparent;
  margin-bottom: 20px;
  // background: #f7f7f7;

  // overflow-y: scroll;
  // min-height: 500px;
  .head {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 10px;
    padding-top: 5px;
    margin-top: 30px;

    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #288add;
      user-select: none;
      white-space: nowrap;
      margin-left: 20px;
    }
  }

  // .line {
  //   // border-bottom: 1px solid #cccccc;
  //   margin-left: 20px;
  //   margin-right: 20px;
  // }

  .returnbt {
    margin-top: 10px;
    margin-right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .bt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }

  .item {
    // padding: 20px 0 20px 30px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    background: #ffffff;

    .title {
      font-size: 20px;
      font-weight: 400;
      color: #222222;
      width: 100%;
      // display: flex;
      // justify-content: flex-start;
      // align-items:center;
    }

    .item-two {
      margin-top: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item-two-bt {
        margin-right: 10px;
        padding: 2px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #222222;
      }

      .item-two-bt-active {
        border: 1px solid #ca261d;
      }
    }

    .text-contain {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;

      .text {
        margin-top: 10px;
        font-size: 15px;

        font-weight: 400;
        color: #222222;
      }
    }

    .bottom-contain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      .bottom-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .teach {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .time {
          margin-right: 10px;
          font-size: 12px;

          font-weight: 400;
          color: #a0a0a0;
        }
      }

      .bottom-right {
        padding-right: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bottom-right-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .elimage2 {
            width: 22px;
            height: 16px;
          }

          .elimage1 {
            width: 20px;
            height: 20px;
            margin-bottom: 4px;
          }

          .elimage3 {
            width: 19px;
            height: 16px;
          }

          .num {
            margin-left: 6px;
            font-size: 15px;

            font-weight: 400;
            color: #a0a0a0;
          }
        }

        .line2 {
          margin-left: 20px;
          margin-right: 20px;
          height: 25px;
          border-right: 2px solid #9b9b9b;
        }
      }
    }

    .replybt {
      margin-right: 10px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      user-select: none;
      cursor: pointer;
      float: right;
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
    }
  }

  // .totaltitle {
  //   margin-left: 30px;
  //   margin-top: 10px;
  //   margin-bottom: 10px;
  //   font-size: 15px;

  //   font-weight: 400;
  //   color: #222222;
  // }
  .totaltitle {
    width: 100%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    //  border: 1px solid green;
    position: relative;
  }

  .totaltitle:before,
  .totaltitle:after {
    position: absolute;
    background: #ccc;
    content: "";
    height: 1px;
    top: 50%;
    width: 45%;
  }

  .totaltitle:before {
    left: 10px;
  }

  .totaltitle:after {
    right: 10px;
  }

  .replycontain {
    box-sizing: border-box;
    background: #ffffff;
    padding-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    .replyitem {
      margin-bottom: 20px;
      margin-left: 40px;

      .itemup {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .nametext {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .timetext {
          font-size: 12px;

          font-weight: 400;
          color: #a0a0a0;
        }
      }

      .itembottom {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .left {
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .right {
          padding-right: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .bottom-right-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            .elimage1 {
              width: 22px;
              height: 16px;
            }

            .elimage2 {
              width: 19px;
              height: 21px;
              margin-bottom: 4px;
            }

            .elimage3 {
              width: 19px;
              height: 16px;
            }

            .num {
              margin-left: 6px;
              font-size: 15px;

              font-weight: 400;
              color: #a0a0a0;
            }
          }

          .line2 {
            margin-left: 20px;
            margin-right: 20px;
            height: 25px;
            border-right: 2px solid #9b9b9b;
          }
        }
      }

      .line3 {
        margin-top: 15px;
        border-bottom: 1px solid #e5e5e5;
      }

      .replychild {
        margin-right: 20px;
        // background: #f7f7f7;
      }
    }
  }

  .dialogbody {
    padding: 20px;
    // z-index: 0;
  }

  .dialogfooter {
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .bt {
      margin-right: 20px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
      user-select: none;
      cursor: pointer;
    }

    .btactive {
      background: #3d84ff;
      border: 1px solid #3d84ff;
      color: white;
    }
  }
}
</style>
